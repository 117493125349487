import React from "react"
import PropTypes from "prop-types"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEONOINDEX from "../components/seonoindex"
import { SlugLink } from '../components/utils'
const TagsPage = ({
  data: {
    privateGraphql: { authors },
    site: {
      siteMetadata: { title, domainName },
    },
  },
  location
}) => {
  if (location != null) {
    if (location.href != null && location.href.endsWith('/')) {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }
  }
  return (
    <Layout location={location} title={title}>
      <SEONOINDEX
        title={`All Authors`}
        description={`All Authors - ${domainName}`}
      />
      <div className="w-grid__columns w-grid__columns--one">
        <div>
          <h1>Authors Page</h1>
          <ul>
            {authors.map(tag => (
              <li key={tag.id}>
                <Link to={`/authors/${tag.slugname}`}>
                  {tag.profilename}
            </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  )
}
TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}
export default TagsPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        domainName
      }
    }
    privateGraphql {
        authors(limit:100,skip:0,siteId: 1066) {
            id
            profilename
            slugname
            image
        }
      }
  }
`
